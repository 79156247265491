import * as rails from "@rails/ujs"
import * as turbolinks from "turbolinks"

if (!window._rails_loaded) {
    rails.start();
    console.log('Rails started')
}
turbolinks.start()

console.log('Vite ⚡️ Rails')

import 'foundation-sites'
import "@fortawesome/fontawesome-free/js/all";

// import $ from 'jquery';
//
// window.$ = $;
import "../javascript/jquery.js" // defines global jQuery for the following Plugins
import 'jqtree'

// import '@ckeditor/ckeditor5-build-classic'
// import '../javascript/init_ckeditor'

import select2 from 'select2';

select2();
import '../javascript/select2_config'

import 'zebra_datepicker/dist/zebra_datepicker.min';

// stimulus
import {initStimulus} from "vite-stimulus-initializer";

const controllers = import.meta.glob('../javascript/**/*-controller.js', {eager: true})
initStimulus(controllers, 2, {debug: true, exclude: ['components', 'views'], folderSeparator: '-'})

import '@csedl/stimulus_dropdown'

import '@nathanvda/cocoon';

// custom javascript
import '../javascript/components/areYouSure';
import '../javascript/components/circle_graph';
import '../javascript/components/cocoon_callbacks';
import '../javascript/components/confirm-with-reveal';
import '../javascript/components/dialog';
import '../javascript/components/forms';
import '../javascript/components/menu';
import '../javascript/components/month_end_closing';
import '../javascript/components/pages';
import '../javascript/components/project-select-staff-dialog';
import '../javascript/components/projects';
import '../javascript/components/sub_contractors';
import '../javascript/components/project-select-staff-dialog';
import '../javascript/components/workload_planning'
import '../javascript/components/jobs'

import '../javascript/views/project/workload-planning'

import {adjust_scrollable_table} from "@/javascript/components/table";
import {adjust_supplements_scrollable_table} from "@/javascript/views/project/supplements-scrollable-table";

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbolinks:load', (event) => {
    initPage();
});

window.addEventListener('resize', () => {
    on_window_resize()
})

function initPage() {

    const main_container = document.getElementById('layout-grid')
    if (main_container.getAttribute('initialized')) {
        return
    }
    main_container.setAttribute('initialized', true)

    $(document).foundation();
    adjust_scrollable_table();

    // date picker

    const date_translations = JSON.parse(document.getElementById('date-picker-translations').innerHTML)
    var months = date_translations.month_names
    months.shift()
    $('input.date_picker').Zebra_DatePicker(
        {
            format: 'd.m.Y',
            days_abbr: date_translations.abbr_day_names,
            months: months,
            show_select_today: date_translations.today_label,
            lang_clear_date: date_translations.clear_date_label
        }
    );

    //$('.tablesorter').tablesorter();
    $('form:not(.skip-dirty-check)').areYouSure( {'message': gon.ays} );
    on_window_resize()
}

function on_window_resize() {
    adjust_scrollable_table()
    adjust_supplements_scrollable_table()

    // Foundation seems to set height, which causes unwanted vertical window scrollbar and does not seem to be necessary.
    // const side_bar = document.getElementsByClassName('side-bar')[0]
    // side_bar.style.height = null
}